import React from 'react'
import  GTrain from '../../images/gtrain.svg';
import  LTrain from '../../images/ltrain.svg';
import {
        LocationPageHero,
        LocationPageHeroTextContainer,
        LocationPageHeroH1,
        LocationPageContainer, 
        LocationPageColumnContainer,
        LocationPageColumnLeft,
        LocationPageColumnCenter,
        LocationPageColumnCenterContainer,
        LocationPageP1,
        LocationPageP2,
        LocationPageColumnRight,
        LocationColumnContainer,
        LocationColumnOne,
        LocationColumnOneContainer,
        LocationColumnOneH1,
        LocationColumnOneP,
        LocationAddressPhone,
        LocationTrainContainer,
        LocationTrainImage,
        LocationTrainP,
        LocationRule,
        LocationColumnTwo,
        HoursContainer,
        MenuHoursTitleContainer,
        HoursTitle,
        HoursRule,
        HoursColumnContainer,
        HoursColumnLeft,
        HoursColumnCenter,
        HoursColumnRight,
        HoursInfoH1,
        HoursInfoP
    } from './LocationPageElements';

const index = () => {
    return (


         <LocationPageContainer>
             
            <LocationPageHero> 
                <LocationPageHeroTextContainer> <LocationPageHeroH1>  Location & Contact </LocationPageHeroH1> </LocationPageHeroTextContainer>
            </LocationPageHero>

            <LocationColumnContainer>

         

            <LocationColumnTwo 
            width={500} height={500} frameBorder={0} scrolling="no" 
            marginHeight={0} marginWidth={0} id="gmap_canvas" 
            src="https://maps.google.com/maps?width=500&height=500&hl=en&q=119%20Nassau%20Ave%20Brooklyn+(Amber%20Steak%20House)&t=p&z=16&ie=UTF8&iwloc=B&output=embed" />

               <LocationColumnOne>
                    
                <LocationColumnOneContainer>

                    <LocationColumnOneH1> We are in Greenpoint, Brooklyn.</LocationColumnOneH1>

                    <LocationAddressPhone>
                        <LocationColumnOneP>
                            119 Nassau Ave,<br />
                            Brooklyn, NY 11222
                        </LocationColumnOneP>

                        <LocationColumnOneP>  
                            718 389 3757 <br />
                            ambersteakhouse@gmail.com 
                        </LocationColumnOneP>
                    </LocationAddressPhone>
                            

                    <LocationColumnOneP>
                         We are a five minute walk from McCarren park. 
                        With MTA subway service you can get the: 
                    </LocationColumnOneP>

                    <LocationTrainContainer>
                    <LocationTrainImage src={GTrain}></LocationTrainImage> <LocationTrainP> to Nassau Ave or <br/> </LocationTrainP>
                    </LocationTrainContainer>

                    <LocationTrainContainer>
                        <LocationTrainImage src={LTrain} /> <LocationTrainP> to Bedford Ave and walk 10 minutes </LocationTrainP>
                    </LocationTrainContainer>

                </LocationColumnOneContainer>

            </LocationColumnOne>

            
            
        </LocationColumnContainer>

        <HoursContainer> 


            <MenuHoursTitleContainer>
            <HoursTitle> Current Hours </HoursTitle>
            <HoursRule />
            </MenuHoursTitleContainer>

            <HoursColumnContainer> 
                <HoursColumnLeft> 
                    <HoursInfoH1>Monday - Thursday</HoursInfoH1>
                    <HoursInfoP> 1PM - 10PM </HoursInfoP>
                </HoursColumnLeft>
                    

                <HoursColumnCenter>
                        <HoursInfoH1> Friday - Saturday </HoursInfoH1>
                        <HoursInfoP> 1PM - 11PM </HoursInfoP>
                </HoursColumnCenter>

                <HoursColumnRight>
                        <HoursInfoH1> Sunday </HoursInfoH1>
                        <HoursInfoP> 1PM - 10PM </HoursInfoP>
                </HoursColumnRight>

            </HoursColumnContainer>
        </HoursContainer>

            <LocationPageColumnContainer>
                <LocationPageColumnLeft />
                <LocationPageColumnCenter> 
                    <LocationPageColumnCenterContainer>   

                        <LocationPageP1>
                        </LocationPageP1>


                    </LocationPageColumnCenterContainer>
                </LocationPageColumnCenter>
                <LocationPageColumnRight />
            </LocationPageColumnContainer>

        </LocationPageContainer>
    )
}

export default index;
