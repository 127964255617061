import React from 'react';
import { AiFillFacebook } from 'react-icons/ai';
import { FaInstagramSquare } from 'react-icons/fa';
import { FooterContainer,
         FooterWrap,
         FooterLinksContainer,
         FooterLinksWrapper,
         FooterLinkItems,
         FooterLinkTitle,
         FooterCopyright,
         FooterCopyrightText,
         FooterInfo,
         FooterSocialLink,
         FollowUsCenter } from './FooterElements';

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> Contact </FooterLinkTitle>
                                <FooterInfo>
                                    119 Nassau Ave, <br /> 
                                    Brooklyn, NY 11222
                                </FooterInfo>
                                <FooterInfo>
                                    718 389 3757
                                </FooterInfo>
                                <FooterInfo>
                                    ambersteakhouse@gmail.com
                                </FooterInfo>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle> Hours </FooterLinkTitle>
                            <FooterInfo>
                                   Monday - Thursday <br />
                                   1PM - 10PM
                            </FooterInfo>
                            <FooterInfo>
                                   Friday - Saturday <br />
                                   1PM - 11PM
                            </FooterInfo>
                            <FooterInfo>
                                   Sunday <br />
                                   1PM - 10PM
                            </FooterInfo>
                                
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                     <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> Follow Us </FooterLinkTitle>

                            <FollowUsCenter>
                                <AiFillFacebook size={25} /> <FooterSocialLink> Facebook </FooterSocialLink>
                            </FollowUsCenter>

                            <FollowUsCenter>
                                <FaInstagramSquare size={25} /> <FooterSocialLink> Instagram </FooterSocialLink>
                            </FollowUsCenter>

                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
            </FooterWrap>
            <FooterCopyright>
                <FooterCopyrightText> &#169; 2021 Amber Steakhouse</FooterCopyrightText>
            </FooterCopyright>
        </FooterContainer>
    )
}

export default Footer;
